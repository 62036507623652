import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/vars/colors.style';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading7, TextCTA } from 'components/TextStyles';
import X from 'components/_svgs/X';
import LinkedIn from 'components/_svgs/LinkedIn';
import { mq } from 'styles/vars/media-queries.style';
import { useMedia } from 'hooks/useMedia';
import Subscribe from 'components/Subscribe';

const Sort = ({ activeType, setActiveType }) => {
  const [allTypes, setAllTypes] = useState([]);

  const sticky = useMedia(true, true, true, true, true, true, false, false);

  const types = useStaticQuery(graphql`
    query BlogTypesQuery {
      allContentfulPagePost {
        distinct(field: typeOfPost)
      }
    }
  `);

  useEffect(() => {
    setAllTypes(['All', ...types.allContentfulPagePost.distinct]);
  }, [types, setAllTypes]);

  const tags = allTypes.map((item) => {
    let hoverColor;
    let activeTextColor;
    let hoverTextColor;
    switch (item) {
      case 'Article':
        hoverColor = colors.accentBlue;
        activeTextColor = colors.humeBlack700;
        hoverTextColor = colors.humeBlack700;
        break;

      case 'Podcast':
        hoverColor = colors.accentPink;
        activeTextColor = colors.humeBlack700;
        hoverTextColor = colors.humeBlack700;
        break;

      case 'Science':
        hoverColor = colors.pastelGreen400;
        activeTextColor = colors.humeBlack700;
        hoverTextColor = colors.humeBlack700;
        break;

      case 'Product Updates':
        hoverColor = colors.accentOrange;
        activeTextColor = colors.humeBlack700;
        hoverTextColor = colors.humeBlack700;
        break;

      default:
        hoverColor = colors.humeBlack700;
        activeTextColor = colors.neutralWhite;
        hoverTextColor = colors.neutralWhite;
        break;
    }

    return (
      <Tag
        key={item}
        active={activeType === item}
        hoverColor={hoverColor}
        activeTextColor={activeTextColor}
        hoverTextColor={hoverTextColor}
        onClick={() => setActiveType(item)}
      >
        <TextCTA>{item}</TextCTA>
        <Box viewBox="0 0 12 12" fill="none">
          {activeType === item && (
            <path d="M2.5 6L5 8.5L10 3.5" stroke="#FFF4E8" />
          )}
          <rect
            x="0.5"
            y="0.5"
            width="11"
            height="11"
            rx="0.5"
            stroke="#FFF4E8"
          />
        </Box>
      </Tag>
    );
  });

  return (
    <Wrapper
      data-scroll
      data-scroll-sticky
      data-scroll-target={sticky ? '#blog-previews' : null}
      data-scroll-offset={sticky ? '0, 220' : null}
    >
      <Section>
        <Heading7 as="span">Topics</Heading7>
        <Tags>{tags}</Tags>
      </Section>
      <Section>
        <Heading7 as="span">Subscribe</Heading7>
        <Subscribe />
      </Section>
      <Section>
        <Heading7 as="span">Follow Us</Heading7>
        <SocialLinks>
          <SocialLink
            href="https://www.linkedin.com/company/hume-ai/"
            aria-label={`Follow us on LinkedIn`}
            target="_blank"
          >
            <LinkedIn />
          </SocialLink>
          <SocialLink
            href="https://twitter.com/hume_ai"
            aria-label={`Follow us on Twitter`}
            target="_blank"
          >
            <X />
          </SocialLink>
        </SocialLinks>
      </Section>
    </Wrapper>
  );
};

export default Sort;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-shrink: 0;
  height: fit-content;
  width: 100%;

  ${mq.mobileL} {
    width: 261px;
  }

  ${mq.desk} {
    width: 334px;
  }

  ${mq.contained} {
    width: 17.4vw;
    gap: 1.56vw;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    text-transform: uppercase;
  }

  ${mq.contained} {
    gap: 0.52vw;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${mq.contained} {
    gap: 0.52vw;
  }
`;

const SocialLink = styled.a`
  border-radius: 50%;
  border: 1px solid currentColor;
  height: 40px;
  position: relative;
  width: 40px;

  svg {
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40px;
  }

  ${mq.contained} {
    width: 2.08vw;
    height: 2.08vw;
    border-width: 0.05vw;

    svg {
      width: 2.08vw;
    }
  }

  &:hover {
    background-color: ${colors.humeBlack700};

    svg {
      path {
        fill: ${colors.humeTan400};
      }
    }
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${mq.contained} {
    gap: 0.52vw;
  }
`;

const Box = styled.svg`
  width: 12px;
  height: auto;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
  border-radius: 4px;
  padding: 0 10px;
  height: 34px;
  color: ${(props) =>
    props.active ? props.activeTextColor : colors.humeBlack700};
  background-color: ${(props) =>
    props.active ? props.hoverColor : colors.neutralWhite};
  border-width: 1px;
  border-color: ${(props) =>
    props.active ? props.hoverColor : colors.humeBlack700};
  border-style: solid;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.hoverColor};
    color: ${(props) => props.hoverTextColor};
    border-color: ${(props) => props.hoverColor};

    ${Box} {
      path,
      rect {
        stroke: ${(props) => props.hoverTextColor};
      }
    }
  }

  ${mq.contained} {
    height: 1.77vw;
    gap: 0.78vw;
    border-radius: 0.21vw;
    padding: 0 0.52vw;
  }

  ${Box} {
    path,
    rect {
      stroke: ${(props) =>
        props.active ? props.activeTextColor : colors.humeBlack700};
    }
  }
`;
