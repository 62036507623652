import React from 'react';
import styled from 'styled-components';
import PageTransitionLink from 'components/PageTransitionLink';
import Img from 'gatsby-image';
import Routes from 'routes';
import Button from 'components/Button';
import { buttonVariants } from 'components/Button/index.style';
import IconArrowheadRight from 'components/_svgs/IconArrowheadRight';
import { mq } from 'styles/vars/media-queries.style';
import { TextStyle } from 'styles/vars/textStyles.style';
import BlogTag from 'components/BlogTag';

const ArticlePreview = ({ image, title, slug, type }) => {
  const href = `${Routes.hub}/${slug}/`;

  return (
    <Wrapper>
      <PageTransitionLink to={href}>
        <Image>
          {image && (
            <Img fluid={image.fluid} alt={image.alt} objectFit="cover" />
          )}
        </Image>
      </PageTransitionLink>
      <BlogTag type={type} />
      <Title>
        <PageTransitionLink to={href}>{title}</PageTransitionLink>
      </Title>
      <ButtonWrapper>
        <Button
          as={`a`}
          variant={buttonVariants.plain}
          href={href}
          iconRight={<IconArrowheadRight />}
        >
          Read More
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ArticlePreview;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  ${mq.mobileL} {
    width: 256px;
  }

  ${mq.desk} {
    width: 16.13vw;
  }

  ${mq.deskL} {
    width: 334px;
  }

  ${mq.contained} {
    width: 17.4vw;
    gap: 1.04vw;
  }
`;

const Image = styled.div`
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;

  a {
    display: block;
  }

  ${mq.contained} {
    border-radius: 0.21vw;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  width: fit-content;
`;

const Title = styled.span`
  ${TextStyle.Heading6}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;

  ${mq.desk} {
    ${TextStyle.Heading5}
  }
`;
