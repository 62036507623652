import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/vars/colors.style';
import ArticlePreview from './ArticlePreview';
import Sort from './Sort';
import { mq } from 'styles/vars/media-queries.style';

const PostList = ({ content }) => {
  const [activeType, setActiveType] = useState('All');

  useEffect(() => {
    if (window?.scroll?.update) {
      window.scroll.update();
    }
  }, [activeType]);

  const posts = content.map(
    (item, index) => {
      if (activeType === 'All' || activeType === item.typeOfPost) {
        return (
          <ArticlePreview
            key={index}
            image={item.heroImage}
            title={item.heroTitle}
            slug={item.slug}
            type={item.typeOfPost}
          />
        );
      }
    },
    [activeType],
  );

  return (
    <Wrapper id="blog-previews">
      <Inner>
        <Sort activeType={activeType} setActiveType={setActiveType} />
        <Previews>{posts}</Previews>
      </Inner>
    </Wrapper>
  );
};

export default PostList;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background: ${colors.neutralWhite};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 72px 22px;
  gap: 55px;

  ${mq.mobileL} {
    flex-direction: row;
    padding: 100px 70px;
  }

  ${mq.desk} {
    gap: 6.94vw;
    padding: 100px 70px 110px;
  }

  ${mq.deskL} {
    gap: 6.94vw;
    padding: 100px 130px 110px;
  }

  ${mq.contained} {
    gap: 7.6vw;
    padding: 5.21vw 6.77vw 5.73vw;
  }
`;

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;

  gap: 85px 25px;

  ${mq.desk} {
    gap: 5.65vw 2.02vw;
    justify-content: flex-start;
  }

  ${mq.contained} {
    gap: 3.65vw;
  }
`;
