import styled from 'styled-components';
import { colors } from 'styles/vars/colors.style';
import { mq } from 'styles/vars/media-queries.style';
import { TextStyle } from 'styles/vars/textStyles.style';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${colors.neutralWhite};
`;

export const Inner = styled.div`
  width: 100%;
  padding: 77px 22px 0px;

  ${mq.mobileL} {
    padding: 134px 70px 0px;
  }

  ${mq.deskL} {
    padding: 166px 133px 0px;
  }

  ${mq.contained} {
    padding: 8.646vw 6.927vw 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.humeBlack700};
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;

  ${mq.mobileL} {
    gap: 70px;
  }

  ${mq.desk} {
    gap: 80px;
    flex-direction: row;
    justify-content: space-between;
  }

  ${mq.contained} {
    gap: 3.646vw;
    padding-bottom: 5.208vw;
  }
`;

export const MainArticle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  flex-grow: 1;

  ${mq.desk} {
    flex-direction: row;
  }

  ${mq.deskL} {
  }

  ${mq.contained} {
    gap: 1.3vw;
  }
`;

export const MainImage = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  overflow: hidden;

  a {
    width: 100%;
  }

  ${mq.desk} {
    width: 30.97vw;
    aspect-ratio: unset;
  }

  ${mq.deskL} {
    height: 100%;
    min-width: 26.28vw;
  }

  ${mq.contained} {
    height: 100%;
    width: 36.15vw;
    border-radius: 0.21vw;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  width: 100%;

  ${mq.desk} {
    width: 23.63vw;
  }

  ${mq.deskL} {
    max-width: 410px;
  }

  ${mq.contained} {
    width: 21.35vw;
    gap: 1.3vw;
  }

  h2 {
    ${mq.contained} {
      flex-shrink: 0;
    }
  }
`;

export const MainTitle = styled.h1`
  ${TextStyle.Heading2}

  ${mq.desk} {
    ${TextStyle.Heading5}
    font-size: 36px;
  }

  ${mq.deskL} {
    ${TextStyle.Heading2}
  }
`;

export const Text = styled.div`
  p {
    ${TextStyle.TextBodySmall}
  }

  ${mq.tabletP} {
    max-width: 712px;
  }
`;

export const Featured = styled.div`
  display: flex;
  gap: 85px;

  ${mq.desk} {
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
  }

  ${mq.contained} {
    gap: 2.86vw;
  }
`;

export const FeaturedArticle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${mq.tabletP} {
    width: 342px;
  }

  ${mq.desk} {
    flex-direction: row;
    align-items: flex-end;
    width: 29.27vw;
  }

  ${mq.deskL} {
    width: 478px;
  }

  ${mq.contained} {
    gap: 1.3vw;
    width: 24.9vw;
  }
`;

export const FeaturedImage = styled.div`
  width: 160px;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;

  a {
    width: 100%;
    height: 100%;
  }

  ${mq.tabletP} {
    width: 256px;
  }

  ${mq.desk} {
    width: 8.23vw;
    aspect-ratio: unset;
  }

  ${mq.deskL} {
    width: 160px;
    align-self: flex-start;
  }

  ${mq.contained} {
    width: 8.33vw;
  }
`;

export const FeaturedTitle = styled.div`
  * {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const FeaturedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${mq.contained} {
    gap: 1.04vw;
  }
`;

export const FeaturedButton = styled.div`
  width: fit-content;
`;
