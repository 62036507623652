import React from 'react';
import Image from 'gatsby-image';
import RichText from 'components/RichText';
import { Heading6 } from 'components/TextStyles';
import {
  Wrapper,
  Inner,
  Content,
  MainArticle,
  MainImage,
  MainContent,
  MainTitle,
  Text,
  Featured,
  FeaturedArticle,
  FeaturedImage,
  FeaturedTitle,
  FeaturedContent,
  FeaturedButton,
} from './index.style';
import Button from 'components/Button';
import { buttonVariants } from 'components/Button/index.style.js';
import IconArrowHeadRight from 'components/_svgs/IconArrowheadRight';
import BlogTag from 'components/BlogTag';
import { useMedia } from 'hooks/useMedia';
import Routes from 'routes';
import PageTransitionLink from 'components/PageTransitionLink';

const HubHero = ({ featuredContent }) => {
  const indexCutoff = useMedia(3, 3, 3, 2, 2, 0, 0, 0);
  const showFeaturedTags = useMedia(true, true, false, true, true, true, true);

  const featuredArticles = featuredContent.map((item, index) => {
    if (index > 0 && index <= indexCutoff) {
      return (
        <FeaturedArticle key={index}>
          <FeaturedImage>
            <PageTransitionLink to={`${Routes.hub}/${item.slug}`}>
              <Image fluid={item.heroImage.fluid} alt={item.heroImage.alt} />
            </PageTransitionLink>
          </FeaturedImage>
          <FeaturedContent>
            {showFeaturedTags && (
              <BlogTag type={item.typeOfPost}>{item.typeOfPost}</BlogTag>
            )}
            <FeaturedTitle>
              <Heading6>{item.heroTitle}</Heading6>
            </FeaturedTitle>
            <FeaturedButton>
              <Button
                as="a"
                href={`${Routes.hub}/${item.slug}/`}
                variant={buttonVariants.plain}
                iconRight={<IconArrowHeadRight />}
              >
                Read More
              </Button>
            </FeaturedButton>
          </FeaturedContent>
        </FeaturedArticle>
      );
    } else return null;
  });

  return (
    <Wrapper>
      <Inner>
        <Content>
          <MainArticle>
            <MainImage>
              <PageTransitionLink
                to={`${Routes.hub}/${featuredContent[0].slug}`}
              >
                <Image fluid={featuredContent[0].heroImage.fluid} />
              </PageTransitionLink>
            </MainImage>
            <MainContent>
              <BlogTag type={featuredContent[0].typeOfPost} />
              <MainTitle>
                <PageTransitionLink
                  to={`${Routes.hub}/${featuredContent[0].slug}`}
                >
                  {featuredContent[0].heroTitle}
                </PageTransitionLink>
              </MainTitle>
              {featuredContent[0].heroCopy && (
                <Text>
                  <RichText content={featuredContent[0].heroCopy} />
                </Text>
              )}
              <Button
                as="a"
                href={`${Routes.hub}/${featuredContent[0].slug}/`}
                variant={buttonVariants.plain}
                iconRight={<IconArrowHeadRight />}
              >
                Read More
              </Button>
            </MainContent>
          </MainArticle>
          <Featured>{featuredArticles}</Featured>
        </Content>
      </Inner>
    </Wrapper>
  );
};

export default HubHero;
