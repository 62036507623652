import React from 'react';
import { graphql } from 'gatsby';

import BlogHero from 'components/Blog/Hero';
import BlogList from 'components/Blog/ArticleList';
import SEO from 'components/SEO';
import ScrollSection from 'components/ScrollSection';
import Footer from 'components/Footer';
import { colors } from 'styles/vars/colors.style';

const IndexPage = (props) => {
  const {
    title,
    metaDescription,
    heroTitle,
    featuredContent,
  } = props.data.contentfulPageBlog;

  return (
    <>
      <SEO
        title={title}
        description={metaDescription?.metaDescription}
        pathname={`blog`}
      />

      <ScrollSection>
        <BlogHero title={heroTitle} featuredContent={featuredContent} />
      </ScrollSection>

      <ScrollSection threshold={0}>
        <BlogList content={props.data.allContentfulPagePost.nodes} />
      </ScrollSection>

      <Footer backgroundColor={colors.neutralWhite} />
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query ContentHubPage {
    contentfulPageBlog {
      title
      metaDescription {
        metaDescription
      }
      heroTitle
      featuredContent {
        slug
        heroTitle
        heroCopy {
          raw
        }
        typeOfPost
        heroImage {
          title
          description
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    allContentfulPagePost(
      sort: { order: DESC, fields: publishedDate }
      filter: { heroTitle: { ne: "dummy article" } }
    ) {
      nodes {
        slug
        heroTitle
        heroCopy {
          raw
        }
        typeOfPost
        heroImage {
          title
          description
          fluid(maxWidth: 900, maxHeight: 900) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
